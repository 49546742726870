import React, {useContext} from "react";
import {
    EditModalWrapper,
    Modal,
    ModalHeader,
    StyledButton,
    FlexColumn
} from "../../../style/projectComponents";
import styled from "styled-components/macro";
import {useMutation} from "react-query";
import {RemoveDepositWalletsReq} from "../../../service/admin-api/models";
import AdminApi from "../../../service/admin-api/AdminApi";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import {useTranslation} from "react-i18next";

const ModalWrapper = styled(Modal)`
  background: ${({theme}) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`;

interface DeleteWalletModalProps {
    openMenu: boolean;
    handleMenu: any;
    walletId: string;
    mutate: any;
    mutateData: any;
}

const DeleteWalletModal: React.FC<DeleteWalletModalProps> = ({
                                                                 openMenu,
                                                                 handleMenu,
                                                                 walletId,
                                                                 mutate,
                                                                 mutateData
                                                             }) => {

    const removeDepositWalletMutation = useMutation((data: RemoveDepositWalletsReq) =>
        AdminApi.removeDepositWallet(data)
    );
    const GlobalModalStorage = useContext(GlobalModalStore);
    const {t} = useTranslation();

    const onSubmit = () => {
        removeDepositWalletMutation.mutate({
            id: walletId
        }, {
            onSuccess: (data) => {
                GlobalModalStorage.makeVisible(true, t("common.success"));
                handleMenu();
                mutate(mutateData);
            },
            onError: (error) => {
                GlobalModalStorage.makeVisible(false, t("common.error"));
            },
        });
    };

    if (openMenu)
        return (
            <ModalWrapper onClick={(e) => {
                if (e.currentTarget === e.target) {
                    if (openMenu) {
                        handleMenu();
                    }
                }
            }}>
                <EditModalWrapper>
                    <ModalHeader>
                        <h3>Confirm Delete Wallet</h3>
                        <img
                            style={{cursor: "pointer"}}
                            onClick={handleMenu}
                            width={14}
                            height={14}
                            src={"/images/buttons/close.png"}
                            alt={"close"}
                        />
                    </ModalHeader>
                    <FlexColumn style={{width: "100%"}}>
                        <StyledButton
                            onClick={onSubmit}
                            variant="primary"
                            style={{width: "100%", height: 44, marginTop: 5, marginBottom: 5}}
                            disabled={removeDepositWalletMutation.isLoading}
                        >
                            Delete Wallet
                        </StyledButton>
                        <StyledButton
                            variant="black"
                            onClick={handleMenu}
                            style={{width: "100%", height: 44}}
                        >
                            {t("menu.cancel")}
                        </StyledButton>
                    </FlexColumn>
                </EditModalWrapper>
            </ModalWrapper>
        );
};

export default DeleteWalletModal;
