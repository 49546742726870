import React from "react";
import { AppTextBig, FlexRow, Modal } from "../../../style/projectComponents";
import styled, { useTheme } from "styled-components/macro";
import { ChangeLanguage } from "../ChangeLanguage";

const LangWrapper = styled(FlexRow)`
  width: 540px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px 25px;
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const LangModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`;
const LangHeader = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 50px;

  img {
    position: absolute;
    top: 20px;
    right: 25px;
  }
`;

interface LanguageModalProps {
  openMenu: boolean;
  handleMenu: () => void;
}

const LanguageModal: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
}) => {
  const theme = useTheme();

  if (openMenu) {
    return (
      <LangModalWrapper
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            if (openMenu) {
              handleMenu();
            }
          }
        }}
      >
        <LangWrapper>
          <LangHeader>
            <AppTextBig style={{ width: "100%" }}>Language</AppTextBig>
            <img
              style={{
                cursor: "pointer",
                filter: theme.colors.white === "#000" && "invert(1)",
              }}
              onClick={handleMenu}
              width={16}
              height={16}
              src={"/images/buttons/close.png"}
              alt={"close"}
            />
          </LangHeader>
          <ChangeLanguage handleMenu={handleMenu} />
        </LangWrapper>
      </LangModalWrapper>
    );
  }
};

export default LanguageModal;
