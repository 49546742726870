import React, { useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
} from "../../style/projectComponents";
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import { FilterMethodButton } from "./FilterMethodButton";
import { isAfter, parse, subMonths, subWeeks, subYears } from "date-fns";

const FilterWrapper = styled(FlexRow)`
  justify-content: end;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 12px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const FilterMethods = styled(FlexRow)`
  gap: 20px;
  @media (max-width: 780px) {
    justify-content: end;
    width: 100%;
  }
`;

const ChartWrapper = styled(FlexColumn)`
  width: 100%;
  height: 500px;

  line {
    stroke: ${({ theme }) => theme.colors.lightGray};
  }
`;

interface ChartComponentProps {
  variant?: "gradient" | "linear";
  data: any;
  xAxisKey?: string;
  yAxisKey?: string;
  titleKey?: string | string[];
  valueKey?: string | string[];
}

const CustomTooltip = (props) => {
  const { active, payload, label, titleKey, valueKey } = props;
  const { t, i18n } = useTranslation();

  const numKeys = ["balance", "deposit"];

  const handleValues = () => {
    if (typeof valueKey === "string") {
      const res = numKeys.find((it) => valueKey.toLowerCase().includes(it))
        ? `${Math.abs(Number(payload[0].payload[valueKey])).toFixed(2)}`
        : payload[0].payload[valueKey];
      return [res];
    } else {
      const res = valueKey.map((it, idx) => {
        let rs;
        if (it === "balanceDiffPercent") {
          rs = payload[0].payload[it];
          const abs = Math.abs(Number(payload[0].payload[it])).toFixed(2);
          if (rs < 0) {
            return `-${abs} %`;
          } else {
            return `+${abs} %`;
          }
        }
        if (
          numKeys.find((it_) => it.toLowerCase().includes(it_.toLowerCase()))
        ) {
          rs = payload[0].payload[it];
          const abs = Math.abs(Number(payload[0].payload[it])).toFixed(2);
          if (rs < 0) {
            return `-${abs}`;
          } else {
            return `${abs}`;
          }
        }
        rs = payload[0].payload[it];
        return rs;
      });
      return res;
    }
  };
  const theme = useTheme();

  if (active && payload && payload.length) {
    const color =
      handleValues()[1] >= 0
        ? `${({ theme }) => theme.colors.greenSuccess}`
        : `${({ theme }) => theme.colors.redFail}`;

    return (
      <FlexColumnStart
        style={{
          background: theme.colors.inputBackgroundSecondary,
          padding: "0.75rem 1rem",
          borderRadius: "0.1875rem",
        }}
      >
        <p className="label" style={{ color: theme.colors.dark, fontSize: 14 }}>
          {`${label}`}
        </p>
        <FlexRow style={{ alignItems: "end" }}>
          <p style={{ fontSize: 16, color: color }}> {handleValues()[0]} </p>
          <p
            style={{
              fontSize: 16,
              color: color,
              marginLeft: 2,
              marginRight: 4,
            }}
          >
            USD
          </p>
          <p style={{ fontSize: 15, color: color }}>
            ({handleValues()[1] >= 0 && "+"}
            {handleValues()[1]})
          </p>
        </FlexRow>
      </FlexColumnStart>
    );
  }

  return null;
};

export const LineChartComponent: React.FC<ChartComponentProps> = (props) => {
  const {
    data,
    titleKey,
    valueKey,
    variant = "gradient",
    xAxisKey = "operationDate",
    yAxisKey = "balanceDiff",
  } = props;

  const formatYAxis = (value) => {
    return Math.round(value);
  };

  const { t, i18n } = useTranslation();
  const chartPeriods = [
    t("dashboard.chart.week1"),
    t("dashboard.chart.month1"),
    t("dashboard.chart.year1"),
    t("dashboard.chart.all"),
  ];
  const [chartPeriodId, setChartPeriodId] = useState(3);

  const formatString = "dd.MM.yy";

  const parsedDate = (date: string) => parse(date, formatString, new Date());
  const subtractedDate =
    (chartPeriodId === 0 && subWeeks(new Date(), 1)) ||
    (chartPeriodId === 1 && subMonths(new Date(), 1)) ||
    (chartPeriodId === 2 && subYears(new Date(), 1));

  const filteredData =
    chartPeriodId < 3
      ? data.filter((dataItem) =>
          isAfter(parsedDate(dataItem.date), subtractedDate)
        )
      : data;

  return (
    <ChartWrapper>
      <ResponsiveContainer
        width="100%"
        height={250}
        key={`rc_${filteredData.length}`}
      >
        <ComposedChart data={filteredData} key={`cc_${filteredData.length}`}>
          <XAxis dataKey={xAxisKey} style={{ fontSize: 14, display: "none" }} />
          <Tooltip
            content={<CustomTooltip titleKey={titleKey} valueKey={valueKey} />}
            contentStyle={{ fontSize: 14, textAlign: "left" }}
          />
          {variant === "gradient" && (
            <Area
              key={`ac_${filteredData.length}`}
              type={"monotone"}
              dataKey={yAxisKey}
              stroke={"#68dbb3"}
              strokeWidth={2}
              fill={"url(#colorUv)"}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <FilterWrapper>
        <FilterMethods>
          {chartPeriods.map((item, idx) => (
            <FilterMethodButton
              title={item}
              isActive={idx === chartPeriodId}
              setActiveTypeId={setChartPeriodId}
              id={idx}
              key={item}
            />
          ))}
        </FilterMethods>
      </FilterWrapper>
    </ChartWrapper>
  );
};
