import {
  InputTitle,
  StyledRegisterButton,
} from "../../style/projectComponents";
import React, { useContext, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import AuthApi from "../../service/auth-api/AuthApi";
import { QRCodeCanvas } from "qrcode.react";
import OtpInput from "../global/OtpInput";
import { queryClient } from "../../config/query-client";
import UserApi from "../../service/user-api/UserApi";
import UserStorage from "../../storage/UserStorage/UserStorage";

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 25px;
  border-radius: 16px;
  width: 526px;
  background: ${({ theme }) => theme.colors.gray050};
  margin-bottom: 30px;

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: calc(100% - 70px);
  }
`;

export const TwoFactorBlock = () => {
  const { t } = useTranslation();

  const UserStore = useContext(UserStorage);
  const { data: userData } = useQuery(
    "user_profile_data",
    () => UserApi.getUserDetails(UserStore.user["UserId"]),
    {
      enabled: !!UserStore.accessToken,
    }
  );
  const twoFactorEnabled = userData?.twoFactorEnabled;

  const enableTwoFaMutation = useMutation(() => AuthApi.enableTwoFactor());
  const confirmTwoFaMutation = useMutation((data: string) =>
    AuthApi.confirmTwoFactor(data)
  );
  const disableTwoFaMutation = useMutation((data: string) =>
    AuthApi.disableTwoFactor(data)
  );

  const GlobalModalStorage = useContext(GlobalModalStore);

  const [qrData, setQrData] = useState(null);
  const onSubmit = (data) => {
    enableTwoFaMutation.mutate(data, {
      onSuccess: (data) => {
        setQrData(data);
        GlobalModalStorage.makeVisible(true, t("common.success"));
      },
      onError: (err) => {
        console.log(err);
        GlobalModalStorage.makeVisible(false, t("common.error"));
      },
    });
  };

  const twoFaButtonText =
    (!twoFactorEnabled && t("auth.enableTwoFa")) || t("auth.disableTwoFa");
  const [otp, setOtp] = useState("");

  const confirmTwoFa = () => {
    confirmTwoFaMutation.mutate(otp, {
      onSuccess: (data) => {
        GlobalModalStorage.makeVisible(true, t("common.success"));
        queryClient.invalidateQueries({ queryKey: ["user_profile_data"] });
        setQrData(null);
        setOtp("");
      },
      onError: (err) => {
        GlobalModalStorage.makeVisible(false, t("common.error"));
      },
    });
  };

  const disableTwoFa = () => {
    disableTwoFaMutation.mutate(otp, {
      onSuccess: (data) => {
        GlobalModalStorage.makeVisible(true, t("common.success"));
        queryClient.invalidateQueries({ queryKey: ["user_profile_data"] });
        setQrData(null);
      },
      onError: (err) => {
        GlobalModalStorage.makeVisible(false, t("common.error"));
      },
    });
  };
  const theme = useTheme();

  return (
    <Block>
      <InputTitle>{t("auth.twoFa")}</InputTitle>
      {!qrData && !twoFactorEnabled && (
        <StyledRegisterButton
          onClick={onSubmit}
          disabled={enableTwoFaMutation.isLoading}
          type={"button"}
          noRadius
          style={{ width: "100%", maxWidth: "100%" }}
        >
          {twoFaButtonText}
        </StyledRegisterButton>
      )}
      {qrData && (
        <QRCodeCanvas
          value={qrData}
          size={160}
          width={160}
          height={160}
          bgColor={"white"}
          includeMargin={false}
          style={{ borderRadius: 8, marginTop: 16, marginBottom: 32 }}
        />
      )}
      {(qrData || twoFactorEnabled) && (
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ marginRight: 8 }} />}
          inputStyle={{
            height: 50,
            width: 24,
            fontSize: 20,
            padding: "0 14px",
            border: `none`,
            borderRadius: 12,
            marginTop: twoFactorEnabled && 24,
            marginBottom: twoFactorEnabled && 16,
            background: theme.colors.inputBackground,
            color: theme.colors.black,
          }}
          renderInput={(props) => <input {...props} />}
        />
      )}
      {qrData && (
        <StyledRegisterButton
          onClick={confirmTwoFa}
          disabled={confirmTwoFaMutation.isLoading}
          type={"button"}
          noRadius
          style={{ width: "100%", maxWidth: "100%" }}
        >
          Confirm
        </StyledRegisterButton>
      )}
      {!qrData && twoFactorEnabled && (
        <StyledRegisterButton
          onClick={disableTwoFa}
          disabled={enableTwoFaMutation.isLoading}
          type={"button"}
          noRadius
          style={{ width: "100%", maxWidth: "100%" }}
        >
          {twoFaButtonText}
        </StyledRegisterButton>
      )}
    </Block>
  );
};
