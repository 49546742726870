/* eslint-disable react-hooks/exhaustive-deps */
import {
    AssetsBlock,
    FlexColumn,
    FlexRow,
    FlexRowResponsive, MarketText,
    StatsWrapper,
    AssetsBlockHeader
} from "../../style/projectComponents";
import {CommonInfoForm} from "../../components/AdminComponents/AdminUserDetail/CommonInfoForm";
import {BalanceAddForm} from "../../components/AdminComponents/AdminUserDetail/BalanceAddForm";
import {BalanceUserDashboard} from "../../components/AdminComponents/AdminUserDetail/BalanceUserDashboard";
import styled from "styled-components/macro";
import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import {
    IGetUserOperationsRequest,
    IStatusOperation,
} from "../../service/user-api/models";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import UserApi from "../../service/user-api/UserApi";
import {StatisticsComponent} from "../../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../../utils/toLocaleNumber";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {observer} from "mobx-react-lite";
import {PercentAddForm} from "../../components/AdminComponents/AdminUserDetail/PercentAddForm";
import {UserOperationsComponent} from "../../components/DashboardComponents/UserOperationsComponent";
import {PageLoader} from "../../components/global/PageLoader";
import {AssetActionsItem} from "../../components/AssetsComponents/AssetActionsItem";
import AdminApi from "../../service/admin-api/AdminApi";
import {IGetCurrenciesRes} from "../../service/admin-api/models";

const Page = styled(FlexColumn)`
  width: 100vw;
  background-color: ${({theme}) => theme.colors.pageBg};
`;

const MainBlock = styled(FlexRow)`
  width: 100%;
  border-radius: 0.75rem;
  gap: 8rem;
  align-items: baseline;
  padding-bottom: 30px;

  @media (max-width: 1100px) {
    gap: 3.12rem;
  }

  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    gap: 3.12rem;
  }
`;

const StyledBg = styled(FlexColumn)`
  background-color: ${({theme}) => theme.colors.pageBg};
  max-width: 1130px;

  gap: 1rem;
  width: 100%;
  padding: 24px;
  height: auto;
  box-sizing: border-box;
  min-height: 100vh;
  justify-content: flex-start;
  margin-top: 110px;
  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 3.5rem;
  }
`;

const MainInfoWrapper = styled(FlexColumn)`
  width: 100%;
  background: ${({theme}) => theme.colors.gray050};
  padding-top: 32px;
  margin-bottom: 16px;
  border-radius: 20px;
  max-width: 1000px;
`

const AdminUserDetail: FC = (props) => {
    const params = useParams();
    const {t} = useTranslation();
    const slug = params["id"];
    const GlobalModalStorage = useContext(GlobalModalStore);

    const {
        data: userData,
        mutate,
        isLoading,
    } = useMutation((userID: string) => UserApi.getUserDetails(userID), {});

    useEffect(() => {
        mutate(slug);
    }, [GlobalModalStorage.windows]);

    const userBalance = userData?.totalBalanceInUsd || 0

    const getOperationsItemsQuery = useMutation(
        (data: IGetUserOperationsRequest) => UserApi.getUserOperationsList(data)
    );


    const [page, setPage] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        if (!!userData?.id) {
            const operationsReq: IGetUserOperationsRequest = {
                statuses: Object.values(IStatusOperation),
                skip: page * itemsPerPage,
                take: itemsPerPage,
                userId: userData.id,
            };
            getOperationsItemsQuery.mutate(operationsReq);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, userData?.id]);

    const totalOperationsPages = useMemo(
        () => (getOperationsItemsQuery.data?.totalCount ? Math.ceil(getOperationsItemsQuery.data?.totalCount / itemsPerPage) : 1),
        [getOperationsItemsQuery.data?.totalCount, itemsPerPage],
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [currencyList, setCurrencyList] = useState<IGetCurrenciesRes>();
    const currencies = useMemo(
        () => currencyList?.currencies || [],
        [currencyList]
    );

    useQuery("currency_list", () =>
            AdminApi.getCurrencies({
                skip: 0,
                take: 50,
            }),
        {
            onSuccess: (data) => {
                setCurrencyList(data);
            },
        }
    );

    // TODO: add action (update currency balance modal)

    return (
        <>
            {isLoading ||
            getOperationsItemsQuery.isLoading ||
            !userData ? (
                <PageLoader/>
            ) : (
                <Page>
                    <StyledBg>
                        <MainInfoWrapper>
                            <StatsWrapper style={{justifyContent: "center", marginBottom: 16}}>
                                <StatisticsComponent
                                    title={`${toLocaleNumber(userBalance)}`}
                                    symbol="USD"
                                    description={t("dashboard.balance")}
                                />
                            </StatsWrapper>

                            <MainBlock>
                                <CommonInfoForm user={userData}/>
                            </MainBlock>

                            <FlexRowResponsive>
                                <BalanceAddForm user={userData}/>
                                <PercentAddForm user={userData}/>
                            </FlexRowResponsive>
                        </MainInfoWrapper>

                        <BalanceUserDashboard user={userData}/>
                        <AssetsBlock>
                            <AssetsBlockHeader>
                                <MarketText style={{width: 150}}>
                                    {t("assets.asset")}
                                </MarketText>
                                <MarketText style={{width: 150}}>
                                    {t("assets.balance")}
                                </MarketText>
                                <MarketText style={{width: 175, textAlign: "right"}}>
                                    {t("assets.actions")}
                                </MarketText>
                            </AssetsBlockHeader>
                            {currencies?.map((cur) => (
                                <AssetActionsItem
                                    usdPrice={cur.usdPrice}
                                    title={cur.name}
                                    fullName={cur?.description}
                                    id={cur.id}
                                    key={cur.id}
                                    networks={cur.availableNetworksForDeposit}
                                    userBalance={  userData?.wallets?.find(
                                        (it) => it.currency.toLowerCase() === cur.name.toLowerCase()
                                    )?.balance || 0}
                                    isAdmin
                                />
                            ))}
                        </AssetsBlock>
                        {getOperationsItemsQuery?.data  && <UserOperationsComponent
                            operations={getOperationsItemsQuery.data}
                            isLoading={getOperationsItemsQuery.isLoading}
                            totalPages={totalOperationsPages}
                            pageId={page}
                            setPageId={setPage}
                        />}
                    </StyledBg>
                </Page>
            )}
        </>
    );
};

export default observer(AdminUserDetail);
