import styled from "styled-components/macro";
import {FlexColumn, Page, StatsWrapper, StyledButton} from "../../style/projectComponents";
import React, {useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {GetExchangeCommissionsStatisticReq} from "../../service/admin-api/models";
import AdminApi from "../../service/admin-api/AdminApi";
import {IGetExchangeRateReq} from "../../service/currency-api/models";
import CurrencyApi from "../../service/currency-api/CurrencyApi";
import {StatisticsComponent} from "../../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../../utils/toLocaleNumber";
import EditCommissionModal from "../../components/AdminComponents/modal/EditCommissionModal";
import {OperationsComponent} from "../../components/DashboardComponents/OperationsComponent";
import {DataEmptyComponent} from "../../components/global/DataEmptyComponent";
import {PaginationComponent} from "../../components/global/PaginationComponent";
import {generatePagesArray} from "../../utils/generatePagesArray";

const StyledBg = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  justify-content: flex-start;
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const AdminCommissions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        data: comsHistoryData,
        isLoading: isLoadingComsHistory,
        mutate: getAllStatsMutate,
    } = useMutation(
        (data: GetExchangeCommissionsStatisticReq) =>
            AdminApi.getExchangeCommissionsStatistic(data), {
            onSuccess: () => {
            }
        }
    );
    const commissionHistoryItems = comsHistoryData?.items;
    const commissionHistoryItemsWithCurrency = commissionHistoryItems?.map(i => (
        {
            ...i,
            currency: i?.wallet?.currency
        }
    ));

    const {data: currencyList} = useQuery("currency_list", () =>
        AdminApi.getCurrencies({
            skip: 0,
            take: 100,
        })
    );
    const currencies = currencyList?.currencies || [];
    const currenciesMutateData = {
        firstTokenId:
            currencies?.[0] && currencies[0]?.id,
        secondTokenId:
            currencies?.[1] && currencies[1]?.id,
        firstTokenAmount: 1,
    };

    const {data: exchangeRate, mutate} = useMutation(
        (data: IGetExchangeRateReq) =>
            CurrencyApi.getExchangeRate(currenciesMutateData)
    );

    useEffect(() => {
        if (currencies?.length > 0) {
            mutate({
                firstTokenId:
                    currencies?.[0] && currencies[0]?.id,
                secondTokenId:
                    currencies?.[1] && currencies[1]?.id,
                firstTokenAmount: 1,
            });
        }
    }, [currencies]);

    const [page, setPage] = useState(0);
    const itemsPerPage = 25;
    const totalPages = useMemo(
        () => (comsHistoryData?.totalCount ? Math.ceil(comsHistoryData?.totalCount / itemsPerPage) : 1),
        [comsHistoryData?.totalCount, itemsPerPage],
    );

    useEffect(() => {
        getAllStatsMutate({
            skip: page * itemsPerPage,
            take: itemsPerPage,
        });
    }, [page]);

    const totalExchangeProfit = comsHistoryData?.totalProfitInUsd || 0;

    const [isOpenChangeComsModal, setIsOpenChangeComsModal] = useState(false);
    const handleOpenComsModal = () => setIsOpenChangeComsModal(o => !o);
    const exchangeRatePercent = exchangeRate?.commissionPercent?.toFixed(3) || 0

    return (
        <Page>
            <EditCommissionModal
                handleMenu={handleOpenComsModal}
                openMenu={isOpenChangeComsModal}
                currentCommission={exchangeRate?.commissionPercent}
                mutate={mutate}
                mutateData={currenciesMutateData}
            />
            <StyledBg>
                <OperationsWrapper>
                    <StatsWrapper style={{alignItems: "start", marginBottom: 32, maxWidth: "100%"}}>
                        <StatisticsComponent
                            title={`${exchangeRatePercent}`}
                            symbol="%"
                            description="Commission percent"
                        />
                        <StatisticsComponent
                            title={`${toLocaleNumber(totalExchangeProfit)}`}
                            symbol="USD"
                            description="Total swap profit"
                        />
                        <StyledButton
                            variant="black"
                            onClick={() => setIsOpenChangeComsModal(true)}
                            style={{height: 44}}
                        >
                            Edit commission percent
                        </StyledButton>
                    </StatsWrapper>
                    <ChartTitle>Commissions History</ChartTitle>
                    {!isLoadingComsHistory && commissionHistoryItems?.length > 0 ? (
                        <OperationsComponent
                            keys={[
                                "operationDate",
                                "walletOwnerEmail",
                                "balanceDiff",
                                "currency",
                                "type",
                                "status",
                            ]}
                            operations={commissionHistoryItemsWithCurrency}
                        />
                    ) : (
                        <DataEmptyComponent/>
                    )}
                    {comsHistoryData?.totalCount > 0 && <PaginationComponent
                        maxPages={totalPages}
                        pageId={page}
                        pagesNum={generatePagesArray(totalPages, page, 100)}
                        setPageId={setPage}
                    />}
                </OperationsWrapper>
            </StyledBg>
        </Page>
    );
};
