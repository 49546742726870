import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  StyledButton,
  StyledLink,
} from "../../../style/projectComponents";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components/macro";
import React, {
  ComponentPropsWithoutRef,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { adminLinks, headerAuthLinks } from "../headerLinks";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminPercent } from "../../../pages/AdminPages/AdminPercent";
import UserStorage from "../../../storage/UserStorage/UserStorage";
import { observer } from "mobx-react-lite";
import { LogoutModal } from "./LogoutModal";
import { AdminCreateInvestPool } from "../../../pages/AdminPages/AdminCreateInvestPool";

const Box = styled(FlexColumnStart)<{ $isAuth?: boolean; isOpen?: boolean }>`
  position: ${({ $isAuth, isOpen }) =>
    $isAuth && isOpen ? "fixed" : "absolute"};
  width: ${(props) => (props.$isAuth && props.isOpen ? "100vw" : "")};
  height: ${(props) => (props.$isAuth && props.isOpen ? "100vh" : "")};
  bottom: ${(props) => (props.$isAuth ? "0" : "")};
  right: ${(props) => (props.$isAuth ? "0" : "")};
  left: ${(props) => (props.$isAuth ? "0" : "")};
  top: ${(props) => (props.$isAuth ? "0" : "")};
  z-index: -100;

  @media (max-width: 600px) {
    z-index: -1;
    width: 100%;
  }
`;

const Wrapper = styled(FlexColumnStart)<{
  $isAuth?: boolean;
  open?: boolean;
  isScrolled?: boolean;
}>`
  position: fixed;
  transform: translateX(${({ open }) => (open ? "0" : "150%")});
  height: 100vh;
  background-color: #000;
  z-index: 100;
  width: 376px;
  padding: 24px;
  right: 0;
  top: 0;
  @media (max-width: 600px) {
    left: 0;
    top: 0;
    padding: 20px;
    height: calc(100%);
    border-radius: 0;
    z-index: -1;
    width: auto;
    min-width: 91%;
    border-left: none;
    align-items: center;
  }
`;

const CustomStyledLink = styled(StyledLink)<{
  mini?: boolean;
  $isAuth?: boolean;
}>`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;

  &[data-admin-btn] {
    flex-direction: row;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      font-size: 20px;
    }

    p {
      padding-right: 10px;
    }
  }

  :hover {
    opacity: 1;
    color: #fff;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export type IMenuModalProps = {
  isAuth?: boolean;
  open: boolean;
  handleMenu: () => void;
  isScrolled?: boolean;
  toggleTheme: any;
  isDarkTheme: boolean;
};

const ExitBtn = styled(FlexRow)<{ $isAuth: boolean }>`
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.redFail};
  }

  :hover {
  }
`;

const BtnWrapper = styled(FlexColumn)`
  gap: 1rem;
  margin-top: 3.75rem;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledScrollLink = styled(ScrollLink)<{
  $isAuth?: boolean;
  mini?: boolean;
}>`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  font-weight: ${(props) => (props.$isAuth && "400") || (!props.mini && "300")};
  line-height: 110%;
  cursor: pointer;

  @media (max-height: 780px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const ThemeButtonsWrapper = styled(FlexRow)`
  margin-top: 16px;
  gap: 16px;
  display: none;
  @media (max-width: 480px) {
    display: flex;
  }
`;
const ThemeButton = styled(FlexRow)`
  width: 20px;
  height: 20px;
  padding: 8px;
  background: none;
  border-radius: 9px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`;

const ColumnMenu = styled(FlexColumnStart)`
  gap: 1.875rem;
`;

const MenuModal: FC<IMenuModalProps & ComponentPropsWithoutRef<"div">> = (
  props
) => {
  const { open, handleMenu, isAuth, toggleTheme, isDarkTheme, ...other } =
    props;
  const { t } = useTranslation();
  const location = useLocation();
  const isAdminPaths = location.pathname.includes("admin");
  const [visiblePercent, setVisiblePercent] = useState<boolean>(false);
  const [createInvestPool, setCreateInvestPool] = useState<boolean>(false);
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
  const UserStore = useContext(UserStorage);
  const isAdmin = UserStore.isAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);

  return (
    <Box
      isOpen={open}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          if (open) {
            handleMenu();
          }
        }
      }}
      $isAuth={isAuth}
    >
      <Wrapper $isAuth={isAuth} open={open} {...other}>
        <ColumnMenu>
          {(isAuth && isAdminPaths ? adminLinks : headerAuthLinks).map(
            (it, idx) =>
              isAuth ? (
                <CustomStyledLink
                  to={it.to}
                  key={idx}
                  $isAuth={isAuth}
                  onClick={() => handleMenu()}
                >
                  {t(it.text)}
                </CustomStyledLink>
              ) : (
                <StyledScrollLink
                  onClick={() => handleMenu()}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  to={it.to}
                  key={idx}
                >
                  {t(it.text)}
                </StyledScrollLink>
              )
          )}

          {isAdminPaths && (
            <>
              <StyledButton
                onClick={() => setVisiblePercent(true)}
                style={{ padding: "var(--16, 1rem)", width: "100%" }}
              >
                {t("adminPercent.accrualInterest")}
              </StyledButton>
              <StyledButton
                variant="black"
                onClick={() => setCreateInvestPool(true)}
                style={{ padding: "var(--16, 1rem)", width: "100%" }}
              >
                Create Invest Pool
              </StyledButton>
            </>
          )}

          {isAuth && isAdmin && (
            <CustomStyledLink
              data-admin-btn
              to={!isAdminPaths ? "/admin/analytics" : "/dashboard"}
            >
              <p>{t("menu.adminPanel")}</p>
              <img src={"/images/door.svg"} alt="" />
            </CustomStyledLink>
          )}

          {isAuth && (
            <ExitBtn onClick={() => setVisibleLogout(true)} $isAuth>
              <span>{t("menu.logout")}</span>
            </ExitBtn>
          )}

          <ThemeButtonsWrapper>
            <ThemeButton
              onClick={() => {
                toggleTheme();
                handleMenu();
              }}
              style={{
                background:
                  isDarkTheme && "linear-gradient(100.72deg, #ff8a00, #d449f7)",
              }}
            >
              <img src="/images/interface/header/moon.svg" alt="" />
            </ThemeButton>
            <ThemeButton
              onClick={() => {
                toggleTheme();
                handleMenu();
              }}
              style={{
                background:
                  !isDarkTheme &&
                  "linear-gradient(100.72deg, #ff8a00, #d449f7)",
              }}
            >
              <img src="/images/interface/header/sun.svg" alt="" />
            </ThemeButton>
          </ThemeButtonsWrapper>
        </ColumnMenu>

        {!isAuth && (
          <BtnWrapper>
            <StyledButton onClick={() => navigate("/register")}>
              {t("auth.reg_title")}
            </StyledButton>

            <StyledButton
              style={{
                height: "auto",
                minHeight: 25,
              }}
              onClick={() => navigate("/login")}
              variant={"text"}
            >
              {t("auth.login")}
            </StyledButton>
          </BtnWrapper>
        )}
      </Wrapper>

      {isAdminPaths && visiblePercent && (
        <AdminPercent setIsOpen={setVisiblePercent} />
      )}
      {isAdminPaths && createInvestPool && (
        <AdminCreateInvestPool setIsOpen={setCreateInvestPool} />
      )}
      {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout} />}
    </Box>
  );
};

export default observer(MenuModal);
