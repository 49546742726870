import styled from "styled-components/macro";
import {
  AppTextBlack,
  FlexColumn,
  Page,
  StyledButton,
} from "../../style/projectComponents";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import AddWalletsModal from "../../components/AdminComponents/modal/AddWalletsModal";
import NetworkService from "../../service/network-api/NetworkApi";
import { AllDepositWalletsReq } from "../../service/admin-api/models";
import { Network } from "../../service/network-api/models";
import {
  SelectLabelItem,
  StyledSelect,
} from "../../components/global/StyledSelect";
import { useNavigate } from "react-router-dom";
import { OperationsComponent } from "../../components/DashboardComponents/OperationsComponent";
import { queryClient } from "../../config/query-client";
import { useGetApiNetworkGetNetworksList } from "../../service/api/generated";

const StyledBg = styled(FlexColumn)`
  width: 100%;
  background: ${({ theme }) => theme.colors.pageBg};
  padding: 24px;
  height: 100%;
  margin-top: 70px;
  justify-content: flex-start;
`;

const ActionsBlock = styled(FlexColumn)`
  width: 540px;
  justify-content: start;
  background: ${({ theme }) => theme.colors.gray050};
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  gap: 24px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

export const AdminWallets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: allDepositWallets,
    isLoading,
    mutate: getAllDepositWalletMutate,
  } = useMutation(
    (data: AllDepositWalletsReq) => AdminApi.getAllDepositWallets(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("get_all_deposit_wallets");
      },
    }
  );
  const [openWalletsModal, setOpenWalletsModal] = useState(false);
  // const { data: networkList } = useQuery("network_list", () =>
  //   NetworkService.getNetworkList()
  // );
  const { data: networkList } = useGetApiNetworkGetNetworksList();
  const [network, setNetwork] = useState<Network>();
  // TODO: add pagination

  const getAllDepositWalletsReqParams = {
    take: 1000,
    skip: 0,
    networkId: network?.id,
    evmComatable: network?.evmCompatible,
  };
  const getWalletsByNetwork = (n: Network) => {
    setNetwork(n);
    getAllDepositWalletMutate(getAllDepositWalletsReqParams);
  };

  const networksForSelect = useMemo(
    () => networkList?.data?.items.map((n) => ({ label: n.name, value: n.id })),
    [networkList]
  );
  const [networkOption, setNetworkOption] = useState<SelectLabelItem>(
    networksForSelect?.[0]
  );

  const navigate = useNavigate();

  const handleOpenUser = (data: any) => {
    if (data.ownerId) {
      navigate(`/admin/${data.ownerId}`);
    }
  };

  return (
    <Page>
      <StyledBg>
        <h1 style={{ margin: "10px" }}>Wallets</h1>
        <ActionsBlock>
          <StyledSelect
            options={networksForSelect}
            classNamePrefix={"Select"}
            value={networkOption}
            onChange={(e) => {
              const event = e as SelectLabelItem;
              setNetworkOption({ label: event.label, value: event.value });
              const network = networkList?.data?.items.find(
                (n) => n.id === event.value
              );
              getWalletsByNetwork(network);
            }}
          />
          <AppTextBlack>{network?.fullName}</AppTextBlack>
          <StyledButton
            onClick={() => setOpenWalletsModal(true)}
            variant={"black"}
            style={{ width: 180 }}
          >
            Add wallets
          </StyledButton>
        </ActionsBlock>
        {allDepositWallets?.items && (
          <OperationsComponent
            keys={["address", "evm", "ownerId", "actions"]}
            onClickItem={handleOpenUser}
            operations={allDepositWallets?.items}
            isAdminWallets
            updateWalletMutate={getAllDepositWalletMutate}
            updateWalletMutateData={getAllDepositWalletsReqParams}
            walletsValidationRegex={network?.walletsValidationRegex || ""}
          />
        )}
      </StyledBg>
      {openWalletsModal && (
        <AddWalletsModal
          handleMenu={() => setOpenWalletsModal((prev) => !prev)}
          openMenu={openWalletsModal}
          network={network}
          getWalletsByNetwork={getWalletsByNetwork}
        />
      )}
    </Page>
  );
};
