/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    FlexColumn,
    Page,
    StatsWrapper,
    StyledBg,
} from "../style/projectComponents";
import {observer} from "mobx-react-lite";
import styled from "styled-components/macro";
import {StatisticsComponent} from "../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../utils/toLocaleNumber";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import UserApi from "../service/user-api/UserApi";
import {
    IGetUserOperationsRequest,
    IGetUserOperationsResponse, IGlobalDashboardItemsRequest, IGlobalDashboardItemsResponse,
    IStatusOperation,
} from "../service/user-api/models";
import ProfileStorage from "../storage/ProfileStorage/ProfileStorage";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {UserOperationsComponent} from "../components/DashboardComponents/UserOperationsComponent";
import {LineChartComponent} from "../components/DashboardComponents/LineChartComponent";
import {MarketComponent} from "../components/DashboardComponents/MarketComponent";
import {
    IGetTickersReq,
    IGetTickersRes,
    ITickerCategory,
} from "../service/bybit-api/models";
import BybitApi from "../service/bybit-api/BybitApi";
import {InvestMethodsComponent} from "../components/DashboardComponents/InvestMethodsComponent";
import InvestPoolApi from "../service/investpool-api/InvestPoolApi";
import {IGetAllInvestPoolsResponse} from "../service/investpool-api/models";

const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 340px;
  max-width: 1100px;
  justify-content: start;
  margin-bottom: 0;
  border-radius: 8px;
  margin-top: 70px;
`;

const MarketWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1000px;
  justify-content: start;
  border-radius: 8px;
  margin-top: 50px;
`;

const StatsAndChartBlock = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.gray050};
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 960px;
  @media (max-width: 780px) {
    max-width: calc(100% - 40px);
  }
`;

const DashboardPage: React.FC = () => {
    const {t} = useTranslation();
    const getDate = (date: string) =>
        format(new Date(date), "dd.MM.yy", {locale: ru});
    const ProfileStore = useContext(ProfileStorage);
    const walletId = ProfileStore.profile?.wallets?.find(
        (it) => it.currency === "USDT"
    )?.id;
    const profileID = ProfileStore.profile?.id;
    const getDashboardItemsQuery = useMutation((data: IGlobalDashboardItemsRequest) =>
        UserApi.getGlobalDashboardItems(data)
    );
    const getOperationsItemsQuery = useMutation(
        (data: IGetUserOperationsRequest) => UserApi.getUserOperationsList(data)
    );
    const getAllInvestPoolsQuery = useMutation(() =>
        InvestPoolApi.getAllInvestPools()
    );
    const [totalInvestProfit, setTotalInvestProfit] = useState(0);
    const [currentInvestPoolsIncome, setCurrentInvestPoolsIncome] = useState(0);
    const [dashboardData, setDashboardData] = useState<IGlobalDashboardItemsResponse | { items?: { balanceDiff?: number }[] }>(null);

    const [investPools, setInvestPools] = useState<IGetAllInvestPoolsResponse>({
        items: [],
        totalCount: 0,
    });
    const investPoolsItems = investPools?.items;

    useEffect(() => {
        if (!!walletId) {
            const dashboardReq: IGlobalDashboardItemsRequest = {
                userId: profileID,
                takeLast: 200,
            };
            getDashboardItemsQuery.mutate(dashboardReq, {
                onSuccess: (data) => {
                    setTotalInvestProfit(data?.totalProfit);
                    setCurrentInvestPoolsIncome(data?.currentInvestPoolsIncome);
                    setDashboardData({
                        ...data,
                        items: data.items.map((it, idx) => ({
                            ...it,
                            date: it.date,
                            balance: it.balanceInUsd,
                            balanceDiff:
                                idx > 0
                                    ? (it.balanceInUsd - data.items.at(idx - 1).balanceInUsd).toFixed(2)
                                    : it.balanceInUsd,
                        })),
                    });
                },
            });
        }
    }, [!!walletId]);


    const [page, setPage] = useState(0);
    const itemsPerPage = 10;

    const [operationsData, setOperationsData] =
        useState<IGetUserOperationsResponse | any>({
            items: [],
            totalCount: 0,
        });
    useEffect(() => {
        if (!!profileID) {
            const operationsReq: IGetUserOperationsRequest = {
                statuses: Object.values(IStatusOperation),
                skip: page * itemsPerPage,
                take: itemsPerPage,
                userId: profileID,
            };
            getOperationsItemsQuery.mutate(operationsReq, {
                onSuccess: (data) => {
                    setOperationsData({
                        ...data, items: data.items.map((it, idx) => ({
                            ...it,
                            balanceDiff: it.balanceDiff.toFixed(8)
                        }))
                    });
                },
            });
            getAllInvestPoolsQuery.mutate(null, {
                onSuccess: (data) => {
                    setInvestPools(data);
                },
            });
        }
    }, [profileID, page]);

    const totalOperationsPages = useMemo(
        () => (operationsData?.totalCount ? Math.ceil(operationsData?.totalCount / itemsPerPage) : 1),
        [operationsData?.totalCount, itemsPerPage],
    );

    const userBalance = ProfileStore?.profile?.totalBalanceInUsd || 0;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [tickers, setTickers] = useState<IGetTickersRes | null>(null);
    const spotTickersList = tickers?.result?.list || null;
    const tickersReq: IGetTickersReq = {
        category: ITickerCategory.spot,
    };
    const getMarketTickersQuery = useQuery(
        ["market_spot_tickers"],
        () => BybitApi.getTickers(tickersReq),
        {
            onSuccess: (data) => {
                setTickers(data);
            },
            refetchInterval: 1500,
        }
    );

    return (
        <Page>
            <StyledBg>
                <StatsAndChartBlock>
                    <StatsWrapper>
                        <StatisticsComponent
                            title={`${toLocaleNumber(userBalance)}`}
                            symbol="USD"
                            description={t("dashboard.balance")}
                        />
                        <StatisticsComponent
                            title={`${toLocaleNumber(totalInvestProfit)}`}
                            symbol="USD"
                            description={t("dashboard.total_invest_profit")}
                        />
                        <StatisticsComponent
                            title={`${toLocaleNumber(currentInvestPoolsIncome)}`}
                            symbol="USD"
                            description={t("dashboard.not_withdraw_profit")}
                        />
                    </StatsWrapper>
                    <BalanceChartWrapper>
                        {dashboardData?.items?.length > 0 && (
                            <LineChartComponent
                                titleKey={[t("common.balanceDiff"), "operationType"]}
                                valueKey={["balance", "balanceDiff"]}
                                xAxisKey={"date"}
                                yAxisKey={"balance"}
                                data={dashboardData.items.map((it) => ({
                                    ...it,
                                    date: getDate(it.date),
                                }))}
                            />
                        )}
                    </BalanceChartWrapper>
                </StatsAndChartBlock>
                {investPoolsItems && investPoolsItems.length > 0 && (
                    <MarketWrapper>
                        <InvestMethodsComponent investPools={investPoolsItems}/>
                    </MarketWrapper>
                )}
                <MarketWrapper>
                    <MarketComponent tickerList={spotTickersList}/>
                </MarketWrapper>
                <UserOperationsComponent
                    operations={operationsData}
                    isLoading={getOperationsItemsQuery.isLoading}
                    totalPages={totalOperationsPages}
                    pageId={page}
                    setPageId={setPage}
                />
            </StyledBg>
        </Page>
    );
};

export default observer(DashboardPage);
